import React from 'react'
import styled from 'styled-components'

// Library
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'
import 'swiper/swiper-bundle.css'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

SwiperCore.use([Autoplay])

const Slides = styled.div`
  & .swiper-slide {
    height: 75px;
  }
`

const Slide = styled.a``

const StyledSlider = styled.section`
  background-color: ${({ theme }) => theme.color.light};
`

const Image = styled(Plaatjie)`
  width: 80%;
  margin: 0 auto;
  max-height: 75px;

  & img {
    filter: grayscale(100%);
    object-fit: contain !important;
  }
`

interface SliderProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Slider
}

const Slider: React.FC<SliderProps> = ({ fields }) => (
  <StyledSlider>
    <div className="container py-5">
      <div className="row py-lg-5">
        <ParseContent content={fields.description} className="mb-4" />
        <Slides className="mt-5">
          <Swiper
            autoplay={{ delay: 5000 }}
            breakpoints={{
              1: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              992: {
                slidesPerView: 4,
              },
            }}
          >
            {fields?.slides?.map((slide, index: number) => (
              <SwiperSlide
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <Slide
                  href={slide?.link?.url}
                  target={slide?.link?.target}
                  className="d-flex justify-content-center align-items-center h-100"
                >
                  <Image
                    image={slide?.image}
                    alt={slide?.link?.title || 'VT Tuinen'}
                  />
                </Slide>
              </SwiperSlide>
            ))}
          </Swiper>
        </Slides>
      </div>
    </div>
  </StyledSlider>
)

export default Slider
